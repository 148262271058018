// import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import legalEntityListVset from '../../legalEntityListVset';
import bankAccountVset from '../../bankAccountVset';
export default {
  name: 'uploadCodFiles',
  components: { legalEntityListVset, bankAccountVset },
  props: ['uploadType'],
  watch: {
    // currentPage1: function() {
    //   this.getLegalEntityData();
    // },
    // perPage1: function() {
    //   this.currentPage1 = 1;
    //   this.getLegalEntityData();
    // },
    // currentPage2: function() {
    //   this.getBankAccNumData();
    // },
    // perPage2: function() {
    //   this.currentPage2 = 1;
    //   this.getBankAccNumData();
    // },
    legalEntity: function() {
      this.bankAccNum = this.defaultValue;
    }
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    bankAccNum: {
      text: { required }
    }
  },
  data() {
    return {
      legalEntity: {
        text: null,
        value: null
      },
      bankAccNum: {
        text: null,
        value: null
      },
      defaultValue: {
        text: null,
        value: null
      },
      // currentPage: 1,
      // perPage1: commonHelper.perPageRecord,
      // pageOptions1: commonHelper.getPageOption(),
      // totalRows1: null,
      // currentPage1: 1,
      // perPage2: commonHelper.perPageRecord,
      // pageOptions2: commonHelper.getPageOption(),
      // totalRows2: null,
      // currentPage2: 1,
      showLegalEntityModal: false,
      // legalEntityData: [],
      // legalEntityFields: [
      //   {
      //     key: 'le_id',
      //     label: 'Legal Entity ID'
      //     // class: 'text-center'
      //   },
      //   {
      //     key: 'le_name',
      //     label: 'Legal Entity Name'
      //     // class: 'text-center'
      //   }
      // ],
      // legalEntityName: null,
      showBankAccNumModal: false,
      showBankStatementModal: false,
      // bankAccData: [],
      // bankAccFields: [
      //   {
      //     key: 'bank_account_id',
      //     label: 'Bank Account ID'
      //   },
      //   {
      //     key: 'bank_account_name',
      //     label: 'Bank Account Name'
      //   },
      //   {
      //     key: 'bank_account_num',
      //     label: 'Bank Account Number',
      //     class: 'text-center'
      //   }
      // ],
      // accountNum: null,
      file: null,
      openModalType: 'uploadCodFile'
    };
  },
  mounted() {},
  methods: {
    mapSelectedVsetData(item) {
      if (item.name === 'uploadFileLegal') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
        this.showLegalEntityModal = false;
      } else if (item.name === 'uploadFileBankAcc') {
        this.bankAccNum = {
          text: item.bank_account_num,
          value: item.bank_account_id
        };
        this.showBankAccNumModal = false;
      }
    },
    showHideLegalEntityModal(flag) {
      this.showLegalEntityModal = flag;
      // this.currentPage1 = 1;
      // if (flag) {
      //   // this.legalEntity = this.defaultValue;
      //   // this.bankAccNum = this.defaultValue;
      //   this.getLegalEntityData();
      // }
    },
    // rowSelectedLegalEntity(item) {
    //   this.legalEntity = {
    //     text: item.le_name,
    //     value: item.le_id
    //   };
    //   this.showLegalEntityModal = false;
    // },
    // getLegalEntityData() {
    //   const payload = {
    //     _page: this.currentPage1 - 1,
    //     _limit: this.perPage1,
    //     le_name: this.legalEntityName
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('codReco/getlegalEntityList', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data.page;
    //         this.legalEntityData = result;
    //         this.totalRows1 = resp.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    showHideBankAccNumModal(flag) {
      if (this.legalEntity.text) {
        this.showBankAccNumModal = flag;
        // if (flag) {
        //   // this.bankAccNum = this.defaultValue;
        //   this.getBankAccNumData();
        // } else {
        //   this.accountNum = null;
        // }
      } else {
        this.$bvToast.toast('Kindly select Legal Entity first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    // rowSelectedBankAccNum(item) {
    //   this.bankAccNum = {
    //     text: item.bank_account_num,
    //     value: item.bank_account_id
    //   };
    //   this.showBankAccNumModal = false;
    //   this.accountNum = null;
    // },
    // getBankAccNumData() {
    //   const payload = {
    //     _page: this.currentPage2 - 1,
    //     _limit: this.perPage2,
    //     le_id: this.legalEntity.value,
    //     bank_account_num: this.accountNum
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('codReco/getBankAccNumList', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data.page;
    //         this.bankAccData = result;
    //         this.totalRows2 = resp.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // clearLegalEntity() {
    //   this.legalEntityName = null;
    //   this.getLegalEntityData();
    // },
    // clearBankAcc() {
    //   this.accountNum = null;
    //   this.getBankAccNumData();
    // },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.text) {
        this.legalEntity = this.defaultValue;
        this.bankAccNum = this.defaultValue;
      } else if (vsetCode === this.bankAccNum.text) {
        this.bankAccNum = this.defaultValue;
      }
      //  else if (vsetCode === this.legalEntityName) {
      //   this.legalEntityName = null;
      // } else if (vsetCode === this.accountNum) {
      //   this.accountNum = null;
      // }
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    clearFile() {
      this.file = null;
    },
    uploadFile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!this.file) {
          alert('Please select a file.');
          return;
        } else {
          let formData = new FormData();
          formData.append('file', this.file);
          formData.append('le_id', this.legalEntity.value);
          formData.append('bank_account_id', this.bankAccNum.value);
          this.loader = true;
          this.$store
            .dispatch(
              this.uploadType === 'bankStatement'
                ? 'codReco/uploadBankStmt'
                : 'codReco/uploadMisDoc',
              formData
            )
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 201) {
                this.$bvToast.toast(response.data.message, {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
              } else {
                this.$bvToast.toast(response.response.data.message, {
                  title: 'Alert',
                  variant: 'danger',
                  solid: true
                });
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    }
  }
};
