import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import uploadCodFiles from './uploadCodFiles';
import legalEntityListVset from '../legalEntityListVset';
import bankAccountVset from '../bankAccountVset';

export default {
  name: 'codReco',
  components: {
    DatePicker,
    uploadCodFiles,
    legalEntityListVset,
    bankAccountVset
  },
  watch: {
    currentPage: function() {
      this.getCodRecoData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCodRecoData();
    },
    currentPage1: function() {
      this.getLegalEntityData();
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getLegalEntityData();
    },
    currentPage2: function() {
      this.getBankAccNumData();
    },
    perPage2: function() {
      this.currentPage2 = 1;
      this.getBankAccNumData();
    },
    legalEntity: function() {
      this.bankAccNum = this.defaultValue;
    }
  },
  data() {
    return {
      loading: false,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      // perPage1: commonHelper.perPageRecord,
      // pageOptions1: commonHelper.getPageOption(),
      // totalRows1: null,
      // currentPage1: 1,
      // perPage2: commonHelper.perPageRecord,
      // pageOptions2: commonHelper.getPageOption(),
      // totalRows2: null,
      // currentPage2: 1,
      legalEntity: {
        text: null,
        value: null
      },
      bankAccNum: {
        text: null,
        value: null
      },
      defaultValue: {
        text: null,
        value: null
      },
      startDate: null,
      endDate: null,
      codRecoData: [],
      codRecoFields: [
        {
          key: 'statement_date',
          label: 'Statement Date',
          class: 'text-center'
        },
        {
          key: 'amount',
          label: 'Bank Statement',
          class: 'text-right colWidth-fix'
        },
        {
          key: 'mis',
          label: 'MIS',
          class: 'text-center'
        },
        {
          key: 'reconciled_receipt',
          label: 'Reconciled Receipt',
          class: 'text-center'
        },
        {
          key: 'unreconciled_receipt',
          label: 'Unreconciled Receipt',
          class: 'text-center'
        }
      ],
      showLegalEntityModal: false,
      // legalEntityData: [],
      // legalEntityFields: [
      //   {
      //     key: 'le_id',
      //     label: 'Legal Entity ID'
      //     // class: 'text-center'
      //   },
      //   {
      //     key: 'le_name',
      //     label: 'Legal Entity Name'
      //     // class: 'text-center'
      //   }
      // ],
      // legalEntityName: null,
      showBankAccNumModal: false,
      showUploadFileModal: false,
      // bankAccData: [],
      // bankAccFields: [
      //   {
      //     key: 'bank_account_id',
      //     label: 'Bank Account ID'
      //   },
      //   {
      //     key: 'bank_account_name',
      //     label: 'Bank Account Name'
      //   },
      //   {
      //     key: 'bank_account_num',
      //     label: 'Bank Account Number',
      //     class: 'text-center'
      //   }
      // ],
      // accountNum: null,
      file: null,
      uploadType: null,
      openModalType: 'codReco'
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    bankAccNum: {
      text: { required }
    },
    startDate: { required },
    endDate: { required }
  },
  mounted() {},
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    showHideLegalEntityModal(flag) {
      this.showLegalEntityModal = flag;
      // this.currentPage1 = 1;
      // if (flag) {
      //   // this.legalEntity = this.defaultValue;
      //   // this.bankAccNum = this.defaultValue;
      //   this.getLegalEntityData();
      // }
    },
    mapSelectedVsetData(item) {
      if (item.name === 'codRecoLegal') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
        this.showLegalEntityModal = false;
      } else if (item.name === 'codRecoBankAcc') {
        this.bankAccNum = {
          text: item.bank_account_num,
          value: item.bank_account_id
        };
        this.showBankAccNumModal = false;
      }
    },
    // rowSelectedLegalEntity(item) {
    //   this.legalEntity = {
    //     text: item.le_name,
    //     value: item.le_id
    //   };
    //   this.showLegalEntityModal = false;
    // },
    // getLegalEntityData() {
    //   const payload = {
    //     _page: this.currentPage1 - 1,
    //     _limit: this.perPage1,
    //     le_name: this.legalEntityName
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('codReco/getlegalEntityList', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data.page;
    //         this.legalEntityData = result;
    //         this.totalRows1 = resp.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    showHideBankAccNumModal(flag) {
      if (this.legalEntity.text) {
        this.showBankAccNumModal = flag;
        // if (flag) {
        //   // this.bankAccNum = this.defaultValue;
        //   this.getBankAccNumData();
        // } else {
        //   this.accountNum = null;
        // }
      } else {
        this.$bvToast.toast('Kindly select Legal Entity first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    // rowSelectedBankAccNum(item) {
    //   this.bankAccNum = {
    //     text: item.bank_account_num,
    //     value: item.bank_account_id
    //   };
    //   this.showBankAccNumModal = false;
    //   this.accountNum = null;
    // },
    // getBankAccNumData() {
    //   const payload = {
    //     _page: this.currentPage2 - 1,
    //     _limit: this.perPage2,
    //     le_id: this.legalEntity.value,
    //     bank_account_num: this.accountNum
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('codReco/getBankAccNumList', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data.page;
    //         this.bankAccData = result;
    //         this.totalRows2 = resp.data.data.total_elements;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // formattedAmount(num) {
    //   return commonHelper.formatAmountWithoutCurrency(num);
    // },
    formattedAmount(num) {
      num = +(+num).toFixed(2);
      num = num.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return num;
    },
    getCodRecoData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          // le_id: this.legalEntity.value,
          bank_account_id: this.bankAccNum.value,
          from_date: this.startDate,
          to_date: this.endDate
        };
        this.loading = true;
        this.$store
          .dispatch('codReco/getCodRecoData', payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.codRecoData = result;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    clearMain() {
      this.legalEntity = this.defaultValue;
      this.bankAccNum = this.defaultValue;
      this.startDate = null;
      this.endDate = null;
      this.codRecoData = [];
    },
    // clearLegalEntity() {
    //   this.legalEntityName = null;
    //   this.getLegalEntityData();
    // },
    // clearBankAcc() {
    //   this.accountNum = null;
    //   this.getBankAccNumData();
    // },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.text) {
        this.legalEntity = this.defaultValue;
        this.bankAccNum = this.defaultValue;
      } else if (vsetCode === this.bankAccNum.text) {
        this.bankAccNum = this.defaultValue;
      }
      //  else if (vsetCode === this.legalEntityName) {
      //   this.legalEntityName = null;
      // } else if (vsetCode === this.accountNum) {
      //   this.accountNum = null;
      // }
    },
    showHideUploadFileModal(flag, type) {
      this.uploadType = type;
      this.showUploadFileModal = flag;
    }
  }
};
