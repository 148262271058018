import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'legalEntityListVset',
  props: ['openModalType'],
  components: {},
  watch: {
    currentPage1: function() {
      this.getLegalEntityData();
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getLegalEntityData();
    }
  },
  data() {
    return {
      loader: false,
      perPage1: commonHelper.perPageRecord,
      pageOptions1: commonHelper.getPageOption(),
      totalRows1: null,
      currentPage1: 1,
      legalEntityData: [],
      legalEntityFields: [
        {
          key: 'le_id',
          label: 'Legal Entity ID'
        },
        {
          key: 'le_name',
          label: 'Legal Entity Name'
        }
      ],
      legalEntityName: null
    };
  },
  mounted() {
    this.getLegalEntityData();
  },
  methods: {
    rowSelectedLegalEntity(item) {
      if (this.openModalType === 'codReco') {
        item.name = 'codRecoLegal';
      } else if (this.openModalType === 'uploadCodFile') {
        item.name = 'uploadFileLegal';
      } else if(this.openModalType === 'uploadProv'){
        item.name = 'uploadProvisions';
      }
      this.$emit('mapSelectedVsetData', item);
    },
    getLegalEntityData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        le_name: this.legalEntityName
      };
      this.loader = true;
      this.$store
        .dispatch('codReco/getlegalEntityList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.legalEntityData = result;
            this.totalRows1 = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntityName) {
        this.legalEntityName = null;
      }
    },
    clearLegalEntity() {
      this.legalEntityName = null;
      this.getLegalEntityData();
    }
  }
};
