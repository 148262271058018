import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'bankAccountVset',
  props: ['leId', 'openModalType'],
  components: {},
  watch: {
    currentPage2: function() {
      this.getBankAccNumData();
    },
    perPage2: function() {
      this.currentPage2 = 1;
      this.getBankAccNumData();
    }
  },
  data() {
    return {
      loader: false,
      perPage2: commonHelper.perPageRecord,
      pageOptions2: commonHelper.getPageOption(),
      totalRows2: null,
      currentPage2: 1,
      bankAccData: [],
      bankAccFields: [
        {
          key: 'bank_account_id',
          label: 'Bank Account ID'
        },
        {
          key: 'bank_account_name',
          label: 'Bank Account Name'
        },
        {
          key: 'bank_account_num',
          label: 'Bank Account Number',
          class: 'text-center'
        }
      ],
      accountNum: null
    };
  },
  mounted() {
    this.getBankAccNumData();
  },
  methods: {
    rowSelectedBankAccNum(item) {
      if (this.openModalType === 'codReco') {
        item.name = 'codRecoBankAcc';
      } else if (this.openModalType === 'uploadCodFile') {
        item.name = 'uploadFileBankAcc';
      }
      this.accountNum = null;
      this.$emit('mapSelectedVsetData', item);
    },
    getBankAccNumData() {
      const payload = {
        _page: this.currentPage2 - 1,
        _limit: this.perPage2,
        le_id: this.leId,
        bank_account_num: this.accountNum
      };
      this.loader = true;
      this.$store
        .dispatch('codReco/getBankAccNumList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.bankAccData = result;
            this.totalRows2 = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.accountNum) {
        this.accountNum = null;
      }
    },
    clearBankAcc() {
      this.accountNum = null;
      this.getBankAccNumData();
    }
  }
};
